import { usePlane } from '@react-three/cannon'
import { useRef } from "react";
import * as THREE from "three"
export function Ground(props) {
    const [ref] = usePlane(() => ({ args: [1, 1], rotation: [-Math.PI / 2, 0, 0], position: [0, -1, 0], ...props }), useRef(null))

    const geometry = new THREE.CylinderGeometry( 20, 60, 20, 32 );
    const material = new THREE.MeshPhongMaterial({

        color:'#f5873c'
    });
    material.specular.setRGB(0,0,0)

    const planeMesh = new THREE.Mesh(geometry,material);
    planeMesh.receiveShadow = true;
    planeMesh.rotation.set(Math.PI / 2, 0, 0)
    planeMesh.position.set(0, 0, -10)

    return (
        <mesh visible={false} ref={ref} receiveShadow={true}>
            <primitive object={planeMesh}></primitive>

        </mesh>
    );
}
