import {useSphere} from "@react-three/cannon";
import {useRef} from "react";
import {useFrame} from "@react-three/fiber";
import ImagePlane from "../Image";
import {Icosahedron} from "@react-three/drei";

function Bubble({material, position,image}) {
    const [ref, body] = useSphere(() => ({
        mass: 1,
        position: position,
        args: [1],
        onCollide: (() => {

            const impulseX = Math.floor(Math.random() * (10 - (-10)) + (-10));
            const impulseY = Math.floor(Math.random() * 10);
            const impulseZ = Math.floor(Math.random() * (10 - (-10)) + (-10));
            body.applyLocalImpulse([impulseX / 10, impulseY / 10, impulseZ / 10], [0, 0, 0])
        }),
    }));
    const main = useRef()
    useFrame(() => {
        body.angularFactor.set(0, 0, 0)
    })
    return (
        <mesh ref={ref}>
            <ImagePlane image={image}/>
            <Icosahedron args={[1, 4]} ref={main} material={material} position={[0, 0, 0]}/>
        </mesh>
    );
}

export default Bubble;