import './App.css';
import {Canvas,} from "@react-three/fiber";
import {Suspense} from "react";
import {Physics} from "@react-three/cannon";
import {Html} from "@react-three/drei";
import Scene from "./components/Scene";


export default function App() {
    return (
        <Canvas
            camera={{position: [0, 3, 25], fov: 90}}
            gl={{powerPreference: 'high-performance', alpha: false, antialias: true, stencil: false, depth: false}}>
            <color attach="background" args={['#ffffff']}/>
            <Suspense fallback={<Html center>Loading.</Html>}>
                <Physics
                    broadphase="SAP"
                    gravity={[0, -0.1, 0]}
                >
                    <Scene/>
                </Physics>
            </Suspense>
        </Canvas>
    )
}
