import {CubeTextureLoader} from "three";
import {useState} from "react";
import {Caustics, MeshDistortMaterial} from "@react-three/drei";
import Bubble from "./Bubble";
import {Ground} from "../Ground";
import {ColliderBox} from "../ColliderBox";



function Scene() {
    const [users,setUser] = useState([
        {image:'./photo.jpg'},
        {image:'./123.jpeg'},
        {image:'https://sun9-72.userapi.com/impg/zPQG6p6ul1PPt3X9hN7Gwzf--bplEXwLWmKgyg/jJ-v0rPuRZg.jpg?size=1280x853&quality=95&sign=d84a0f0aa5bed45059cbb2388d343473&type=album'},
    ])
    const envMap = new CubeTextureLoader().setPath('/cube/').load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']);
    // We use `useResource` to be able to delay rendering the spheres until the material is ready
    const [matRef, material] = useState()
    const config = {
        bounces: 1,
        aberrationStrength: 0.01,
        ior: 2,
        fresnel: 1,
        color: 'white',
        fastChroma: true
    }
    return (
        <>
            <Caustics backfaces
                      color={config.color}
                      position={[0, -0.5, 0]}
                      lightSource={[5, 5, -10]}
                      worldRadius={0.1}
                      ior={0}
                      backfaceIor={1.1}
                      intensity={1}>

                <MeshDistortMaterial
                    ref={material}
                    color={'white'}
                    transparent={true}
                    opacity={0.5}
                    roughness={0.1}
                    metalness={1}
                    envMap={envMap}
                    {...config}
                />
            </Caustics>
            <Ground/>
            <ColliderBox position={[0, 15, 0]} scale={[70, 0.1, 70]}/>

            <ColliderBox position={[15, 7, 0]} scale={[0.1, 15, 70]}/>
            <ColliderBox position={[-15, 7, 0]} scale={[0.1, 15, 70]}/>
            <ColliderBox position={[0, 7, 10]} scale={[70, 15, 0.1]}/>
            <ColliderBox position={[0, 7, -5]} scale={[70, 15, 0.1]}/>

            {users.map((item,index)=>{
                const posX = Math.floor(Math.random() * 10);
                const posY = Math.floor(Math.random() * 10);
                const posZ = Math.floor(Math.random() * 10);
                return(
                    <mesh key={index}>
                    {material && <Bubble image={item.image} position={[posX, posY, posZ]} material={matRef}/>}
                    </mesh>
                )
            })}

        </>
    )
}

export default Scene;