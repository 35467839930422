import * as THREE from 'three'

import React, { useRef} from 'react'
import {useLoader} from "@react-three/fiber";



function ImagePlane(props) {
    const ref = useRef();
console.log(props)

    const texture = useLoader(THREE.TextureLoader, props.image)
    return (
        <mesh ref={ref}>
            <planeGeometry attach="geometry" args={[1.3, 1.3]} />
            <meshBasicMaterial attach="material" map={texture} toneMapped={false} />
        </mesh>
    )
}

export default ImagePlane;
